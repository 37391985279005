* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

.Header {
  padding: 2rem;
}

.App {
  padding: 2rem;
}

.Container_big_padding {
  padding-left: 15rem;
  padding-right: 15rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
}

.Container {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Container_small {
  padding: 0,5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Container_zero_padding {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Container_zero_padding_not_center {
  display: flex;
  flex-direction: column;
}

.Container_small_right {
  padding: 0,5rem;
  display: flex;
  justify-content: right;
  align-items: right;
}

.Container_small_left {
  padding: 0,5rem;
  display: flex;
  justify-content: left;
  align-items: left;
}

.Container_small_middle {
  padding: 0,5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container_middle {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container_Modal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.Container_Modal_Message_mobile {
  padding-top: 5px;
  padding-right: 69%;
  display: flex;
  flex-direction: column;
}

.Container_buyCode {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container_Text_Center {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.Container_bottom {
  padding: 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

#card {
  outline: 5px solid #F17221;
  transform: none;
}

#card-button {
  margin-top: auto; /* Schiebt den Button nach unten */
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#card-selected{
  background-color: #000000;
  color: #FFFFFF;
  transform: none;
}

#card-selected-productname{
  background-color: #000000;
  color: #FFFFFF;
  transform: none;
  margin-top: 0px;
  padding-top: 2px;
}

#card-unselected-productname{
  transform: none;
  margin-top: 0px;
  padding-top: 2px;
}

#menu_padding{
  padding-left: 4.59rem;
  padding-right: 4.59rem;
}

#item-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#modal_center{
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

#br_replacer{
  margin: 10px 0 10px 0;
  display: block;
}

.Footer {
  padding: 0.5rem;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 600px) {
  .App {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width: 400px) {
  .App {
    padding: 4rem 1rem;
  }
}
/*
@media screen and (max-width: 1000px) {
  .ui.menu .item .header {
    visibility: hidden;
  }
  .ui.menu .item .header .icon {
    display: inline !important;
    visibility: visible !important;
  }
}
*/

/* Cookie Banner Styles */
.cookie-banner {
  position: fixed;
  bottom: 0.5rem;
  left: 1rem;
  right: 1rem;
  background-color: #f0ebeb;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  padding: 1rem;
  border-radius: 0.75rem;
}

@media (min-width: 768px) {
  .cookie-banner {
    left: 2rem;
    right: 2rem;
    padding: 1.5rem;
  }
}

.cookie-banner-container {
  max-width: 80rem;
  margin: 0 auto;
}

.cookie-banner-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

@media (min-width: 768px) {
  .cookie-banner-main {
    flex-direction: row;
  }
}

.cookie-banner-content {
  flex: 1;
}

.cookie-banner-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.cookie-banner-description {
  font-size: 0.875rem;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cookie-banner-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.cookie-settings {
  margin-bottom: 1rem;
}

.cookie-setting-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.cookie-setting-content h4 {
  font-weight: 500;
}

.cookie-setting-content p {
  font-size: 0.875rem;
  color: #666;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: 500;
}

.btn-outline {
  border: 1px solid #ccc;
  background-color: transparent;
}

.btn-primary {
  background-color: #F17221;
  color: white;
}

.checkbox {
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}